import {Component} from '@angular/core';
import {
  DangerInformation,
  Exemption_5_4,
  Exemption_5_4_ReasonType,
  Exemption_5_4_ConditionType
} from '@portbase/bezoekschip-service-typescriptmodels';
import {ExemptionBaseComponent} from "../exemption_base/exemption_base.component";
import {PortvisitUtils} from "../../../../../refdata/portvisit-utils";

@Component({
  selector: 'app-visit-exemptions-exemption-5-4',
  templateUrl: './exemption_5_4.component.html',
  styleUrls: ['./exemption_5_4.component.css']
})
export class Exemption_5_4Component extends ExemptionBaseComponent {

  exemptionSubType: Exemption_5_4;

  portVisitUtils = PortvisitUtils;

  reasonTypes: Exemption_5_4_ReasonType[] = [
    null,
    Exemption_5_4_ReasonType.TRANSSHIPMENT,
    Exemption_5_4_ReasonType.REPARATION,
    Exemption_5_4_ReasonType.CLEANING,
    Exemption_5_4_ReasonType.WAITING,
    Exemption_5_4_ReasonType.OTHER
  ];

  conditionTypes: Exemption_5_4_ConditionType[] = [
    null,
    Exemption_5_4_ConditionType.LOADED_WITH_FLAMMABLE_LIQUID,
    Exemption_5_4_ConditionType.LOADED_OR_NOT_GAS_FREE,
    Exemption_5_4_ConditionType.EMPTY_AND_CLEAN
  ];

  reasonTypesFormatter = (reasonType: Exemption_5_4_ReasonType) => {
    switch (reasonType) {
      case null:
        return 'None';
      case "TRANSSHIPMENT":
        return 'Transshipment';
      case "REPARATION":
        return 'Reparation';
      case "CLEANING":
        return 'Cleaning';
      case "WAITING":
        return 'Waiting';
      case "OTHER":
        return 'Other';
    }
  };

  conditionTypesFormatter = (conditionType: Exemption_5_4_ConditionType) => {
    switch (conditionType) {
      case null:
        return 'None';
      case "LOADED_WITH_FLAMMABLE_LIQUID":
        return 'LOADED_WITH_FLAMMABLE_LIQUID';
      case "LOADED_OR_NOT_GAS_FREE":
        return 'LOADED_OR_NOT_GAS_FREE';
      case "EMPTY_AND_CLEAN":
        return 'EMPTY_AND_CLEAN';
    }
  };

  dangerInformationFormatter = (dangerInformation: DangerInformation) => {
    return dangerInformation.name;
  };
}

