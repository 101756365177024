<ng-container>

  <div>
    <b>{{ name }}</b>
    <app-info class="formGroupLabel" [wide]="false" [placement]="'left'">
      <div class="tooltipContent">
        <b>Considering</b>
        <ul>
          <div *ngFor="let consider of splitConsidering()">
            <li>
              {{consider}}
            </li>
          </div>
        </ul>
      </div>
    </app-info>
  </div>

  <app-visit-exemptions-exemption-general [exemption]="exemption"></app-visit-exemptions-exemption-general>

  <ng-container>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Reason for request for exemption">
          <app-select [(ngModel)]="exemption['reasonType']" id="reasonType" [options]="reasonTypes"
                      [formatter]="reasonTypesFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Planned pull/push force (Nm)" *ngIf="exemption['reasonType'] === 'PUSH_PULL_TEST'">
          <input [(ngModel)]="exemption['plannedPullPushForce']" id="plannedPullPushForce" type="number" maxlength="10" class="form-control" required>
        </app-form-group>
        <app-form-group label="From" *ngIf="exemption['reasonType'] === 'PUSH_PULL_TEST'">
          <app-date-field [(ngModel)]="exemption['from']" id="from" required></app-date-field>
        </app-form-group>
        <app-form-group label="To" *ngIf="exemption['reasonType'] === 'PUSH_PULL_TEST'">
          <app-date-field [(ngModel)]="exemption['to']" id="to" required></app-date-field>
        </app-form-group>
        <app-form-group label="Reason" *ngIf="exemption['reasonType'] === 'OTHER'">
          <textarea [(ngModel)]="exemption['reason']" id="reason" type="text" rows="3" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Details">
          <textarea [(ngModel)]="exemption['details']" id="details" type="text" rows="3" maxlength="256" class="form-control"></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>
  </ng-container>
</ng-container>
