<ng-container>

  <div>
    <b>{{ name }}</b>
    <app-info class="formGroupLabel" [wide]="false" [placement]="'left'">
      <div class="tooltipContent">
        <b>Considering</b>
        <ul>
          <div *ngFor="let consider of splitConsidering()">
            <li>
              {{consider}}
            </li>
          </div>
        </ul>
      </div>
    </app-info>
  </div>

  <app-visit-exemptions-exemption-general [exemption]="exemption"></app-visit-exemptions-exemption-general>

  <ng-container>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Condition of Cargo tanks">
          <app-select [(ngModel)]="exemption['requestType']" id="conditionType" [options]="requestTypes"
                      [formatter]="requestTypesFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="The vessel has a working VHF radio system on which the relevant VHF channel is continuously monitored">
          <input [(ngModel)]="exemption['hasVesselWorkingVHF']" id="hasVesselWorkingVHF" type="checkbox" class="form-check-input" required>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="The electrical system meets at least the requirements as per Atex Zone 2 of Directive 1999/92/EC">
          <input [(ngModel)]="exemption['isElectricalSystemMeetingRequirements']" id="isElectricalSystemMeetingRequirements" type="checkbox" class="form-check-input" required>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Heating, cooking and cooling appliances run on electricity or flammable liquids with a combustion point of 55°C or higher">
          <input [(ngModel)]="exemption['areAppliancesRunningOnElectricity']" id="areAppliancesRunningOnElectricity" type="checkbox" class="form-check-input" required>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="The vessel has accommodation that provides adequate protection against the ingress of hazardous substances">
          <input [(ngModel)]="exemption['hasVesselAccommodation']" id="hasVesselAccommodation" type="checkbox" class="form-check-input" required>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="The motor is shielded such that it cannot act as an ignition source">
          <input [(ngModel)]="exemption['isMotorShielded']" id="isMotorShielded" type="checkbox" class="form-check-input" required>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Reason for request for exemption">
          <textarea [(ngModel)]="exemption['reason']" id="reason" type="text" rows="3" minlength="20" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Details">
          <textarea [(ngModel)]="exemption['details']" id="details" type="text" rows="3" maxlength="256" class="form-control"></textarea>
        </app-form-group>
      </div>
    </div>
  </ng-container>
</ng-container>
