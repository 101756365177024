<div class="row fieldset">
  <div class="col-md">
    <app-form-group label="Request made on behalf of">
      <app-select id="onBehalfOf" [(ngModel)]="exemption['onBehalfOf']"
                  [options]="onBehalfOfOptions" required>
      </app-select>
    </app-form-group>
  </div>
  <div class="col-md">
    <app-form-group label="Other specifically" *ngIf="exemption['onBehalfOf'] == 'Other'">
      <input id="onBehalfOfOther" [(ngModel)]="exemption['onBehalfOfOther']" type="text"
             maxlength="30" class="form-control" required>
    </app-form-group>
  </div>
</div>
<div class="row fieldset">
  <div class="col-md">
    <app-form-group label="Name of requester">
      <input [(ngModel)]="exemption['nameOfRequester']" id="nameOfRequester" type="text"
             maxlength="30" class="form-control" required>
    </app-form-group>
  </div>
  <div class="col-md">
    <app-form-group label="Telephone number of applicant">
      <input [(ngModel)]="exemption['telephoneNumberOfApplicant']" id="telephoneNumberOfApplicant"
             type="text" maxlength="30" class="form-control" required value="+31">
    </app-form-group>
  </div>
</div>
<div class="row fieldset">
  <div class="col-md">
    <app-form-group label="Email address of applicant">
      <input [(ngModel)]="exemption['emailAddressOfApplicant']" id="6" type="text" maxlength="256"
             class="form-control" required>
    </app-form-group>
  </div>
  <div class="col-md">
    <app-form-group label="Terminal agreement">
      <div style="display: flex; justify-content: flex-end; gap: 10px;">
        <button class="btn btn-secondary" role="button" (click)="uploadTerminalAgreements.click()"
                title="Upload exemption forms">
          <span class="fa fa-file-upload"></span>
          <input type="file" hidden multiple
                 (change)="upload($event.target.files); $event.target.value = '';"
                 #uploadTerminalAgreements>
          Upload
        </button>
        <input disabled type="text" [ngModel]="" maxlength="30" class="form-control" style="width: 225px;"
               #uploadedTerminalAgreements>
      </div>
    </app-form-group>
  </div>
</div>
