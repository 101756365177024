<ng-container>

  <div>
    <b>{{ name }}</b>
    <app-info class="formGroupLabel" [wide]="false" [placement]="'left'">
      <div class="tooltipContent">
        <b>Considering</b>
        <ul>
          <div *ngFor="let consider of splitConsidering()">
            <li>
              {{consider}}
            </li>
          </div>
        </ul>
      </div>
    </app-info>
  </div>

  <app-visit-exemptions-exemption-general [exemption]="exemption"></app-visit-exemptions-exemption-general>

  <ng-container>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>Vessel name registration/IMO – ENI number</label>
            <div class="tooltipContent">Name of bunker vessel </div>
          </app-info>
          <app-search [(ngModel)]="vessel" (ngModelChange)="onVesselChange(vessel)" [searchFunction]="searchVessel" [inputFormatter]="vesselFormatter"
                      [minCharacters]="3" dataKey="imoCode" id="vessel">
          </app-search>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="First vessel name">
          <input [(ngModel)]="exemption['vesselName']" id="vesselName" type="text" rows="3" maxlength="64" class="form-control" readonly>
        </app-form-group>
        <app-form-group label="First vessel IMO">
          <input [(ngModel)]="exemption['vesselIMO']" id="vesselIMO" type="text" rows="3" maxlength="64" class="form-control" readonly>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="From">
          <app-date-field [(ngModel)]="exemption['from']" id="from" required></app-date-field>
        </app-form-group>
        <app-form-group label="To">
          <app-date-field [(ngModel)]="exemption['to']" id="to" required></app-date-field>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Fuel to be bunkered">
          <app-select [(ngModel)]="exemption['fuelType']" id="berthType" [options]="fuelTypes"
                      [formatter]="fuelTypesFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Is the location on the bunker map ?">
          <input [(ngModel)]="exemption['isLocationOnBunkerMap']" id="isLocationOnBunkerMap" type="checkbox" class="form-check-input">
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Is the terminal qualified as having Bunker Readiness ?">
          <input [(ngModel)]="exemption['isTerminalQualified']" id="isTerminalQualified" type="checkbox" class="form-check-input">
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Reason for request for exemption">
          <textarea [(ngModel)]="exemption['reason']" id="reason" type="text" rows="3" minlength="20" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Details">
          <textarea [(ngModel)]="exemption['details']" id="details" type="text" rows="3" maxlength="256" class="form-control"></textarea>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-info class="formGroupLabel">
          <label>Annexes</label>
          <div class="tooltipContent">Add the “Joint Plan of Operation” and the “Compatibility study”</div>
        </app-info>
        <app-form-group>
          <textarea [(ngModel)]="exemption['annexes']" id="annexes" type="text" rows="3" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>
  </ng-container>
</ng-container>
