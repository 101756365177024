<ng-container *ngIf="consignmentProcess">
  <div class="modal-header">
    <div class="flex-container align-items-center">
      <button type="button" class="btn-close me-2" data-bs-dismiss="modal" aria-label="Close"></button>
      <div class="d-inline-flex me-1">
        <i class="fa fa-fw fa-light fa-lg fa-file-lines text-muted"></i>
      </div>
      <span class="page-title">{{ masterLevel.consignmentNumber }}</span>
      <app-tooltip *ngIf="consignmentProcess.cancelled" placement="bottom" class="ms-2">
        <span class="fa text-danger fa-fw fa-ban"></span>
        <span class="tooltipContent">Cancelled</span>
      </app-tooltip>
    </div>
  </div>

  <div class="modal-body p-0" [ngClass]="editMode ? 'edit-mode' : ''">
    <app-consignment-details-alerts [consignmentProcess]="consignmentProcess"></app-consignment-details-alerts>
    <app-tab-panel [sticky]="true" [alwaysRender]="true">
      <app-tab-item [templateRef]="detailsRef">
        <span class="tab-item">Details</span>
        <ng-template #detailsRef>
          <div class="container py-3">
            <div class="card">
              <div class="card-body p-5">
                <div class="row gy-4 my-0">
                  <h5 class="h1 col-12 m-0">Master bill of lading details</h5>
                  <div class="col-12" *ngIf="mainFilingChoice">
                    <div class="row gy-4">
                      <app-form-field label="Customs" class="col-12 col-md-6"
                                      [(value)]="mainFilingChoice"
                                      [includeMargin]="false" [editMode]="editMode" [renderOptional]="false"
                                      [formatter]="refData.enumFormatter" [tooltipTemplateRef]="mainFilingTooltip">
                        <app-radio [(ngModel)]="mainFilingChoice"
                                   (ngModelChange)="updateFilingType()"
                                   [inline]="true" [options]="mainFilingChoiceTypes" [formatter]="refData.enumFormatter"
                                   [required]="true"></app-radio>
                      </app-form-field>
                    </div>
                  </div>
                  <div class="col-12" *ngIf="billOfLadingFilingType && (mainFilingChoice == MainFilingChoice.YES)">
                    <div class="row gy-4">
                      <app-form-field label="Mode of transport" class="col-6 col-md-3"
                                      [(value)]="billOfLadingFilingType.modeOfTransportType"
                                      [includeMargin]="false" [editMode]="editMode" [renderOptional]="false"
                                      [formatter]="refData.enumFormatter" [tooltipTemplateRef]="modeOfTransportTooltip">
                        <app-radio [(ngModel)]="billOfLadingFilingType.modeOfTransportType"
                                   (ngModelChange)="updateFilingType()"
                                   [inline]="true" [options]="modeOfTransportTypes" [formatter]="refData.enumFormatter"
                                   [required]="true"></app-radio>
                      </app-form-field>
                      <app-form-field *ngIf="!rules.isRoadConsignment(consignmentProcess)"
                                      label="Bill of lading type" class="col-6 col-md-3"
                                      [(value)]="billOfLadingFilingType.billOfLadingType"
                                      [includeMargin]="false" [editMode]="editMode" [renderOptional]="false"
                                      [formatter]="refData.enumFormatter" [tooltipTemplateRef]="billOfLadingTooltip">
                        <app-radio [(ngModel)]="billOfLadingFilingType.billOfLadingType"
                                   (ngModelChange)="billOfLadingTypeChanged($event)"
                                   [inline]="true" [options]="billOfLadingTypes" [formatter]="refData.enumFormatter"
                                   [required]="true"></app-radio>
                      </app-form-field>
                      <app-form-field *ngIf="!rules.isRoadConsignment(consignmentProcess)" label="Filing"
                                      class="col-6 col-md-3"
                                      [(value)]="billOfLadingFilingType.filing"
                                      [includeMargin]="false" [editMode]="editMode" [renderOptional]="false"
                                      [formatter]="refData.enumFormatter" [tooltipTemplateRef]="filingTypeTooltip">
                        <app-radio [(ngModel)]="billOfLadingFilingType.filing" (ngModelChange)="updateFilingType()"
                                   [inline]="true" [options]="filingTypes" [formatter]="refData.enumFormatter"
                                   required></app-radio>
                      </app-form-field>
                    </div>
                  </div>
                  <ng-container *ngIf="showDetails() ">
                    <div class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Master bill of lading number" class="col-6 col-md-4"
                                        [(value)]="masterLevel.consignmentNumber"
                                        [includeMargin]="false" [editMode]="editMode && isNewConsignment"
                                        [renderOptional]="false">
                          <input class="input-group rounded border form-control" (change)="masterLevel.consignmentNumber=$event.target.value.toUpperCase()"
                                 [(ngModel)]="masterLevel.consignmentNumber" required
                                 (ngModelChange)="setConsignmentProcessId($event)"/>
                        </app-form-field>
                        <app-form-field
                          *ngIf="rules.isSplintConsignmentAllowed(consignmentProcess)"
                          label="Split from BL with MRN" class="col-6 col-md-4"

                                        [(value)]="consignmentProcess.filing.splitConsignmentPreviousMrn"
                                        [editMode]="editMode"
                                        [includeMargin]="false">
                          <input class="input-group rounded border form-control"
                                 [(ngModel)]="consignmentProcess.filing.splitConsignmentPreviousMrn"/>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Trader assigned reference" class="col-6 col-md-4"
                                        [(value)]="masterLevel.traderAssignedReference"
                                        [editMode]="editMode"
                                        [includeMargin]="false">
                          <input class="input-group rounded border form-control"
                                 [(ngModel)]="masterLevel.traderAssignedReference"/>
                        </app-form-field>
                        <app-form-field label="ENS movement reference number" class="col-6 col-md-4"
                                        [includeMargin]="false" [(value)]="mrn" [editMode]="editMode && !consignmentProcess.filing.declarant?.ensEnabled">
                          <input class="input-group rounded border form-control" [(ngModel)]="mrn"/>
                        </app-form-field>
                        <app-form-field label="TS movement reference number" class="col-6 col-md-4"
                                        [includeMargin]="false"
                                        [value]="consignmentProcess.status?.filingStatusTemporaryStorage?.registrationResponse?.mrn"
                                        [editMode]="false">
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Call reference number" class="col-6 col-md-4"
                                        [(value)]="consignmentProcess.filing.crn" [editMode]="editMode"
                                        [includeMargin]="false">
                          <app-search [(ngModel)]="consignmentProcess.filing.crn" (ngModelChange)="onCrnChange($event)"
                                      placeholder="Search for visit" dataKey="crn" [required]="rules.isCrnRequired(this.consignmentProcess)"
                                      [inputFormatter]="utils.visitFormatter" [resultFormatter]="utils.visitFormatter"
                                      [minCharacters]="2" [searchFunction]="utils.findVisits"></app-search>
                        </app-form-field>
                        <app-form-field label="Voyage number" class="col-6 col-md-4"
                                        [(value)]="masterLevel.agentVoyageNumber"
                                        [editMode]="editMode" [includeMargin]="false">
                          <input class="input-group rounded border form-control"
                                 [disabled]="!rules.isVoyageNumberAllowed(consignmentProcess)"
                                 [(ngModel)]="masterLevel.agentVoyageNumber"/>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12" *ngIf="!rules.isRoadConsignment(consignmentProcess)">
                      <div class="row gy-4">
                        <app-form-field label="Vessel" class="col-6 col-md-4"
                                        [(value)]="masterLevel.activeBorderTransportMeans.vehicle"
                                        [editMode]="editMode" [includeMargin]="false" [formatter]="vehicleFormatter">
                          <app-search
                            [(ngModel)]="masterLevel.activeBorderTransportMeans.vehicle"
                            [searchFunction]="searchVessel" [inputFormatter]="vehicleFormatter" [disabled]="rules.isDutchPort(consignmentProcess)"
                            [minCharacters]="3" placeholder="Name or IMO number" dataKey="imoCode" id="vesselName"
                            required>
                          </app-search>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12" *ngIf="rules.isRoadConsignment(consignmentProcess)">
                      <app-form-field label="Carrier" class="col-6 col-md-4"
                                      [(value)]="masterLevel.carrier"
                                      [editMode]="editMode"
                                      [includeMargin]="false" [formatter]="utils.carrierFormatter">
                        <app-search required [(ngModel)]="masterLevel.carrier"
                                    [disabled]="rules.isDutchPort(consignmentProcess)"
                                    [searchFunction]="utils.findCarriers" [resultFormatter]="utils.carrierFormatter"
                                    [inputFormatter]="utils.carrierFormatter">
                        </app-search>
                      </app-form-field>
                    </div>
                    <div class="col-12" *ngIf="rules.isRoadConsignment(consignmentProcess)">
                      <span class="d-block fw-regular mb-3">Vehicle</span>
                      <div class="row gy-4">
                        <app-form-field label="Identification type" class="col-6 col-md-4"
                                        [value]="masterLevel.activeBorderTransportMeans.vehicle.typeOfIdentification"
                                        [editMode]="editMode"
                                        [includeMargin]="false"
                                        [formatter]="typeOfIdentificationFormatter">
                          <app-select required
                                      [(ngModel)]="masterLevel.activeBorderTransportMeans.vehicle.typeOfIdentification"
                                      [options]="typeOfIdentifications"
                                      [formatter]="typeOfIdentificationFormatter"></app-select>
                        </app-form-field>
                        <app-form-field label="Description" class="col-6 col-md-4"
                                        [value]="masterLevel.activeBorderTransportMeans.vehicle.typeOfMeansOfTransport"
                                        [editMode]="editMode"
                                        [formatter]="typeOfMeansOfTransportFormatter"
                                        [includeMargin]="false">
                          <app-select required
                                      [(ngModel)]="masterLevel.activeBorderTransportMeans.vehicle.typeOfMeansOfTransport"
                                      [options]="allTypeOfMeansOfTransportKeys"
                                      [selectedFormatter]="typeOfMeansOfTransportFormatter"
                                      [formatter]="typeOfMeansOfTransportFormatter"></app-select>
                        </app-form-field>
                      </div>
                      <div class="row gy-4">
                        <app-form-field label="Identification" class="col-6 col-md-4"
                                        [(value)]="masterLevel.activeBorderTransportMeans.vehicle.identificationNumber"
                                        [editMode]="editMode"
                                        [includeMargin]="false">
                          <input required class="input-group rounded border form-control"
                                 [(ngModel)]="masterLevel.activeBorderTransportMeans.vehicle.identificationNumber"/>
                        </app-form-field>
                        <app-form-field label="Registration Nationality" class="col-6 col-md-4"
                                        [(value)]="masterLevel.activeBorderTransportMeans.vehicle.nationality"
                                        [editMode]="editMode"
                                        [includeMargin]="false">
                          <app-search required [(ngModel)]="masterLevel.activeBorderTransportMeans.vehicle.nationality"
                                      [searchFunction]="refData.findCountries" [resultFormatter]="refData.countryFormatter" [inputFormatter]="countryFormatter"
                                      (ngModelChange)="masterLevel.activeBorderTransportMeans.vehicle.nationality = countryFormatter($event)">
                          </app-search>
                        </app-form-field>
                      </div>
                    </div>
                    <ng-container *ngIf="editMode">
                      <div class="col-12" *ngIf="rules.isPlaceOfAcceptanceMasterLevelAllowed(consignmentProcess)">
                        <div class="row gy-4">
                          <app-form-field label="Place of acceptance" class="col-6 col-md-4"
                                          [(value)]="masterLevel.placeOfAcceptance"
                                          [editMode]="editMode" [includeMargin]="false" [formatter]="utils.placeFormatter"
                                          tooltip="The location where the goods are taken over from the consignor">
                            <app-search [(ngModel)]="masterLevel.placeOfAcceptance"
                                        [required]="rules.isPlaceOfAcceptanceMasterLevelRequired(consignmentProcess)"
                                        [inputFormatter]="utils.placeFormatter" (ngModelChange)="updateRoutes()"
                                        [searchFunction]="refData.findLocation"></app-search>
                          </app-form-field>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row gy-4">
                          <app-form-field label="Place of loading" class="col-6 col-md-4"
                                          [(value)]="masterLevel.placeOfLoading"
                                          [editMode]="editMode"
                                          [includeMargin]="false" [formatter]="utils.placeFormatter">
                            <app-search [(ngModel)]="masterLevel.placeOfLoading"
                                        (ngModelChange)="onPlaceOfLoadingChange($event)"
                                        [inputFormatter]="utils.placeFormatter"
                                        [searchFunction]="refData.findLocation" required></app-search>
                          </app-form-field>
                          <app-estimated-actual-field class="col-6 col-md-8" [(estimate)]="masterLevel.activeBorderTransportMeans.estimatedDateAndTimeOfDeparture"
                                                      [(actual)]="masterLevel.activeBorderTransportMeans.actualDateAndTimeOfDeparture"
                                                      [editMode]="editMode" label="Time of departure"
                                                      (estimateChange)="updateRoutes()" (actualChange)="updateRoutes()"></app-estimated-actual-field>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row gy-4">
                          <app-form-field label="Port of discharge" class="col-4 col-md-4"
                                          [(value)]="masterLevel.placeOfUnloading"
                                          [editMode]="editMode" [includeMargin]="false"
                                          [formatter]="utils.placeFormatter">
                            <app-search [(ngModel)]="masterLevel.placeOfUnloading"
                                        (ngModelChange)="onPlaceOfUnloadingChange($event)"
                                        [inputFormatter]="utils.placeFormatter"
                                        [searchFunction]="refData.findLocation" required></app-search>
                          </app-form-field>
                          <app-form-field *ngIf="rules.isDischargeTerminalAllowed(consignmentProcess)"
                                          label="Terminal" class="col-4 col-md-4"
                                          [(value)]="masterLevel.dischargeTerminal"
                                          [editMode]="editMode" [includeMargin]="false"
                                          [formatter]="refData.terminalFormatter">
                            <app-select *ngIf="!!terminalsOfVisit" [(ngModel)]="masterLevel.dischargeTerminal"
                                        [formatter]="refData.terminalFormatter" (ngModelChange)="onTerminalChange($event)"
                                        [optionsProvider]="terminalsOfVisit" dataKey="terminalCode" required
                                        placeholder="Search for terminal">
                            </app-select>
                          </app-form-field>
                          <app-form-field *ngIf="rules.isDutchPort(consignmentProcess) && false" label="Terminal License" class="col-4 col-md-4"
                                          [(value)]="masterLevel.terminalLicense"
                                          [editMode]="editMode" [includeMargin]="false">
                            <input class="input-group rounded border form-control"
                                   [(ngModel)]="masterLevel.terminalLicense"/>
                          </app-form-field>
                        </div>
                      </div>
                    </ng-container>
                    <div *ngIf="rules.isPlaceOfDeliveryMasterLevelAllowed(consignmentProcess)" class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Place of delivery" class="col-6 col-md-4"
                                        [(value)]="masterLevel.placeOfDelivery"
                                        [editMode]="editMode" [includeMargin]="false"
                                        [formatter]="utils.placeFormatter" tooltip="The location at which the goods are to be received by the consignee">
                          <app-search [(ngModel)]="masterLevel.placeOfDelivery"
                                      [inputFormatter]="utils.placeFormatter" (ngModelChange)="updateRoutes()"
                                      [required]="rules.isPlaceOfDeliveryMasterLevelRequired(consignmentProcess)"
                                      [searchFunction]="refData.findLocation"></app-search>
                        </app-form-field>
                      </div>
                    </div>
                    <div *ngIf="!editMode || !consignmentProcess.filing.crn" class="col-12">
                      <div class="d-inline-block mb-3">
                        <span class="fw-regular">Route</span>
                        <app-tooltip *ngIf="rules.isTemporaryStorageOnlyConsignment(consignmentProcess.filing.filingType)" [placement]="'top'" class="ms-1">
                          <i class="fa-light fa-circle-info info-icon text-black"></i>
                          <div class="tooltipContent">
                            <div class="tooltip-card">
                              <div class="tooltip-card-body">
                                <div class="text-start">
                                  <h6>Filing type</h6>
                                  <p>The filing type has been automatically set to {{consignmentProcess.filing.filingType}} based on the itinerary</p>
                                  <p>This means no ENS will be send by Portbase</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </app-tooltip>
                      </div>
                      <p *ngIf="editMode">A minimum of 2 countries is required, one of which must be a member of the
                        European Union.</p>
                      <div class="consignment-routes row gy-4">
                        <div class="col-12" *ngFor="let route of routes; let i = index">
                          <app-master-consignment-route [routeInfo]="route" [index]="i" [editMode]="editMode"
                                                        [(allRoutes)]="routes"></app-master-consignment-route>
                        </div>
                      </div>
                      <div *ngIf="editMode" class="row mt-3">
                        <div class="col-auto">
                          <button class="btn btn-outline-primary text-decoration-none" (click)="addRoute()">
                            <i class="button-icon fa-light fa-plus"></i>
                            <span class="ps-2 d-none d-md-inline">Add country</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="editMode && rules.isReEntryIndicatorAllowed(consignmentProcess)" class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="European Union re-entry?" class="col-6 col-md-6"
                                        [(value)]="consignmentProcess.filing.reEntryIndicator"
                                        [editMode]="editMode" [includeMargin]="false">
                          <app-yes-no [required]=rules.isReEntryIndicatorRequired(consignmentProcess)
                                      [(ngModel)]="consignmentProcess.filing.reEntryIndicator"></app-yes-no>
                        </app-form-field>
                      </div>
                    </div>
                    <div *ngIf="editMode && rules.isCustomsOfficeFirstEntryAllowed(consignmentProcess)" class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Customs office first entry" class="col-6 col-md-4"
                                        [(value)]="consignmentProcess.filing.customsOfficeOfFirstEntry"
                                        [editMode]="editMode" [includeMargin]="false"
                                        [formatter]="utils.customsOfficeFormatter">
                          <app-select [(ngModel)]="consignmentProcess.filing.customsOfficeOfFirstEntry"
                                      [required]="rules.isCustomsOfficeFirstEntryRequired(consignmentProcess)"
                                      [options]="customsOffices" dataKey="name" (ngModelChange)="updateRoutes()"
                                      [formatter]="utils.customsOfficeFormatter"></app-select>
                        </app-form-field>
                      </div>
                    </div>
                    <div *ngIf="editMode" class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Estimated time of arrival" class="col-6 col-md-6"
                                        [(value)]="masterLevel.activeBorderTransportMeans.estimatedDateAndTimeOfArrival"
                                        [editMode]="editMode" [includeMargin]="false">
                          <app-date-field
                            [(ngModel)]="masterLevel.activeBorderTransportMeans.estimatedDateAndTimeOfArrival"
                            required></app-date-field>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <ng-container
                          *ngIf="masterLevel.transportCharges && rules.isTransportChargesAllowed(consignmentProcess)">
                          <app-form-field label="Payment method" class="col col-md-6"
                                          [(value)]="masterLevel.transportCharges.methodOfPayment"
                                          [editMode]="editMode" [includeMargin]="false"
                                          [formatter]="utils.methodOfPaymentFormatter">
                            <app-select
                              [(ngModel)]="masterLevel.transportCharges.methodOfPayment"
                              [options]="utils.methodsOfPayment"
                              [required]="rules.isTransportChargesAllowed(consignmentProcess)"
                              [formatter]="utils.methodOfPaymentFormatter"></app-select>
                          </app-form-field>
                        </ng-container>
                        <ng-container *ngIf="rules.isExchangeRateAllowed(this.consignmentProcess) && masterLevel.exchangeRate">
                          <app-form-field  label="Currency" class="col-6 col-md-3"
                                          [(value)]="masterLevel.exchangeRate.currency"
                                          [editMode]="editMode"
                                          [includeMargin]="false" [formatter]="refData.currenciesFormatter">
                            <app-select [(ngModel)]="masterLevel.exchangeRate.currency"
                                        [nullOption]="true"
                                        dataKey="unCode" [optionsProvider]="refData.getCurrencies"
                                        [formatter]="refData.currenciesFormatter"
                                        [selectedFormatter]="refData.compactCurrenciesFormatter"></app-select>
                          </app-form-field>
                          <app-form-field label="Exchange rate" class="col col-md-3"
                                          [(value)]="masterLevel.exchangeRate.rate"
                                          [editMode]="editMode"
                                          [includeMargin]="false">
                            <input class="input-group rounded border form-control"
                                   [(ngModel)]="masterLevel.exchangeRate.rate"/>
                          </app-form-field>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-12" *ngIf="rules.isCustomsStatusAllowed(consignmentProcess)">
                      <div class="row gy-4">
                        <app-form-field label="Transport condition" class="col-6 col-md-4"
                                        [(value)]="masterLevel.transportConditionCode"
                                        [editMode]="editMode"
                                        [includeMargin]="false" [formatter]="refData.enumFormatter">
                          <app-select [(ngModel)]="masterLevel.transportConditionCode"
                                      [options]="utils.transportConditionCodes"
                                      [formatter]="refData.enumFormatter"></app-select>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row gy-4">
                        <app-form-field *ngIf="rules.isBulkAuthorizationAllowed(consignmentProcess)" label="Authorisations" [editMode]="editMode"
                                        tooltip="Authorise organisations like surveyors to access the Discharge Information of this B/L (if bulk)"
                                        [(value)]="masterLevel.bulkAuthorisations"
                                        [formatter]="bulkAuthorisationFormatter">
                          <app-multi-search [ngModel]="masterLevel.bulkAuthorisations"
                                            [searchFunction]="refData.findBulkAuthorisations"
                                            (ngModelChange)="setBulkAuthorisations($event)" dataKey="shortName"
                                            [inputFormatter]="refData.bulkAuthorisationFormatter"
                                            [resultFormatter]="refData.bulkAuthorisationFormatter"
                                            placeholder="Find organisation">
                          </app-multi-search>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <app-form-field *ngIf="rules.isCustomsStatusAllowed(consignmentProcess)" label="Customs status" class="col-6 col-md-3"
                                        [(value)]="masterLevel.customsStatus"
                                        [editMode]="editMode"
                                        [includeMargin]="false" [formatter]="refData.enumFormatter">
                          <app-select [(ngModel)]="masterLevel.customsStatus"
                                      [options]="utils.customsStatuses"
                                      [formatter]="refData.enumFormatter"></app-select>
                        </app-form-field>
                        <app-form-field *ngIf="rules.isCustomsProcessAllowed(this.consignmentProcess)" label="Customs process" class="col col-md-6"
                                        [(value)]="masterLevel.customsProcess"
                                        [editMode]="editMode"
                                        [includeMargin]="false" [formatter]="utils.customProcessNameFormatter">
                          <app-select [(ngModel)]="masterLevel.customsProcess"
                                      [options]="utils.customsProcesses" (ngModelChange)="updateFilingType()"
                                      [formatter]="utils.customProcessNameFormatter"></app-select>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12" *ngIf="ConsignmentRules.isPreviousDocumentAllowed(consignmentProcess)">
                      <label class="h2 fw-regular">Documents</label>
                      <span *ngIf="editMode && !ConsignmentRules.isPreviousEnsDocumentRequired(consignmentProcess)" class="form-field-optional text-secondary ms-1">(optional)</span>
                      <div class="row">
                        <app-form-field label="Previous document" class="col col-md-7"
                                        [(value)]="masterLevel.previousDocument.type"
                                        [editMode]="editMode"
                                        [includeMargin]="false" [formatter]="utils.supportingDocumentTypeFormatter">
                          <app-select [(ngModel)]="masterLevel.previousDocument.type"
                                      placeholder="Select document type"
                                      [options]="utils.documentTypes"
                                      [formatter]="utils.supportingDocumentTypeFormatter"
                                      [required]="ConsignmentRules.isPreviousEnsDocumentRequired(consignmentProcess)"></app-select>
                        </app-form-field>
                        <app-form-field label="Reference number"
                                        [(value)]="masterLevel.previousDocument.identification"
                                        class="col col-md-3"
                                        [editMode]="editMode" [includeMargin]="false">
                          <input class="input-group rounded border form-control"
                                 [required]="ConsignmentRules.isPreviousEnsDocumentRequired(consignmentProcess)"
                                 [(ngModel)]="masterLevel.previousDocument.identification"
                                 (ngModelChange)="updatePreviousDocument($event)"/>
                        </app-form-field>
                      </div>
                    </div>
                    <ng-container
                      *ngFor="let supportingDocument of masterLevel.supportingDocuments; index as i; trackBy: refData.trackByIndex">
                      <div class="col-12">
                        <div class="row">
                          <app-form-field label="Supporting documents" class="col col-md-7" [truncateReadOnly]="true"
                                          [value]="supportingDocument.type" [editMode]="editMode"
                                          [includeMargin]="false" [formatter]="utils.documentTypeFormatter">
                            <app-search [(ngModel)]="supportingDocument.type"
                                        [inputFormatter]="utils.documentTypeFormatter"
                                        [searchFunction]="utils.findDocumentTypes"
                                        placeholder="Select a document type"></app-search>
                          </app-form-field>
                          <app-form-field label="Reference number" [value]="supportingDocument.referenceNumber"
                                          class="col col-md-3"
                                          [editMode]="editMode" [includeMargin]="false">
                            <input class="input-group rounded border form-control" required
                                   [(ngModel)]="supportingDocument.referenceNumber"/>
                          </app-form-field>
                          <div *ngIf="editMode" class="col-auto d-flex align-items-center pt-5">
                            <button class="btn rounded-circle p-1 px-2 text-danger h1 mb-0"
                                    (click)="deleteSupportingDocument(supportingDocument)">
                              <i class="fa-light fa-trash-can"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <div *ngIf="editMode" class="row mt-3">
                        <div class="col-auto">
                          <button class="btn btn-outline-primary text-decoration-none"
                                  (click)="addSupportingDocument()">
                            <i class="button-icon fa-light fa-plus"></i>
                            <span class="ps-2 d-none d-md-inline">Add supporting document</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </app-tab-item>
      <ng-container *ngIf="showDetails()">
        <app-tab-item [templateRef]="supplyChainActorsRef">
          <span class="tab-item">Parties</span>
          <ng-template #supplyChainActorsRef>
            <div class="container py-3">
              <div class="card">
                <div class="card-body p-5">
                  <div class="row gy-4 my-3">
                    <h5 class="h1 col-12 m-0">Parties</h5>
                    <div class="col-12">
                      <app-consignment-carrier-details [(carrier)]="masterLevel.carrier"
                                                       [required]="true"
                                                       [editMode]="editMode" [cargoDeclarantId]="consignmentProcess.filing.declarant.iamConnectedId"
                                                       label="Carrier"></app-consignment-carrier-details>
                    </div>
                    <ng-container *ngIf="!rules.isStraightConsignment(consignmentProcess)">
                      <div class="col-12">
                        <app-consignment-party-details
                          [(party)]="masterLevel.consignee"
                          [editMode]="editMode" [cargoDeclarantId]="consignmentProcess.filing.declarant.iamConnectedId"
                          label="Consignee"></app-consignment-party-details>
                      </div>
                      <div class="col-12">
                        <app-consignment-party-details
                          [(party)]="masterLevel.consignor"
                          [cargoDeclarantId]="consignmentProcess.filing.declarant.iamConnectedId"
                          [editMode]="editMode" [required]="rules.isMasterConsignment(consignmentProcess)"
                          label="Consignor"></app-consignment-party-details>
                      </div>
                      <div class="col-12" *ngIf="consignmentProcess.filing.filingType === 'F12'">
                        <app-consignment-supplementary-declarant
                          [(supplementaryDeclarant)]="masterLevel.supplementaryDeclarant"
                          [supplementaryFilingType]="'N1'"
                          [editMode]="editMode" required
                          label="Supplementary declarant"></app-consignment-supplementary-declarant>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="masterLevel.notifyParties?.length || editMode">
                      <h5 class="h1 col-12">Notify parties</h5>
                      <div class="col-12" *ngFor="let party of masterLevel.notifyParties; index as i; trackBy: refData.trackByIndex">
                        <app-consignment-party-details
                          [(party)]="masterLevel.notifyParties[i]" (partyChange)="checkPartyDeletion($event, i)"
                          [cargoDeclarantId]="consignmentProcess.filing.declarant.iamConnectedId"
                          [editMode]="editMode" [required]="true" [deletionAllowed]="true" [communicationRequired]="true"
                          [label]="party.name"></app-consignment-party-details>
                      </div>
                      <div class="col-12" *ngIf="editMode">
                        <div class="col-auto">
                          <button type="button" class="btn btn-primary" (click)="addNotifyParty()">Add notify party</button>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div *ngIf="!rules.isStraightConsignment(consignmentProcess) && !rules.isRoadConsignment(consignmentProcess)" class="row gy-4 my-3">
                    <ng-container *ngIf="masterLevel.additionalSupplyChainActors?.length || editMode">
                      <h5 class="h1 col-12 m-0">Supply chain actors</h5>
                      <div class="col-12"
                           *ngFor="let supplyChainActor of masterLevel.additionalSupplyChainActors; index as i; trackBy: refData.trackByIndex">
                        <app-additional-supply-chain-actor-details [consignmentProcess]="consignmentProcess"
                                                                   [supplyChainActor]="supplyChainActor"
                                                                   [editMode]="editMode"
                                                                   (supplyChainActorChange)="checkSupplyChainActorDeletion($event, i)"
                                                                   [label]="utils.supplyChainTypeFormatter(supplyChainActor.type)"></app-additional-supply-chain-actor-details>
                      </div>
                      <div class="col-12" *ngIf="editMode">
                        <div class="col-auto">
                          <button type="button" class="btn btn-primary" (click)="addSupplyChainActor()">Add actor
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </app-tab-item>
        <app-tab-item
          *ngIf="!rules.isStraightConsignment(consignmentProcess) && !rules.isRoadConsignment(consignmentProcess)"
          [templateRef]="goodsRef">
          <span class="tab-item">Goods
            <span class="ps-2 sub-text fw-light">{{ goodsItems.length }}</span>
          </span>
          <ng-template #goodsRef>
            <app-consignment-goods-table [data]="goodsItems" [editable]="isEditable"
                                         [consignmentProcess]="consignmentProcess"></app-consignment-goods-table>
          </ng-template>
        </app-tab-item>
        <app-tab-item *ngIf="equipmentSupported" [templateRef]="equipmentRef">
          <span class="tab-item">Equipment
            <span class="ps-2 sub-text fw-light">{{ transportEquipmentCount }}</span>
          </span>
          <ng-template #equipmentRef>
            <app-consignment-equipments-table [data]="equipments" [editable]="isEditable"
                                              [consignmentProcess]="consignmentProcess"></app-consignment-equipments-table>
          </ng-template>
        </app-tab-item>
        <app-tab-item *ngIf="utils.houseConsignmentSupported(consignmentProcess)" [templateRef]="houseConsignmentsRef">
          <span class="tab-item">House B/Ls
            <span
              class="ps-2 sub-text fw-light">{{ masterLevel.consignmentsHouseLevel.length }}</span>
          </span>
          <ng-template #houseConsignmentsRef>
            <app-house-consignments-table [data]="houseConsignments" [editable]="isEditable"
                                          [consignmentProcess]="consignmentProcess"></app-house-consignments-table>
          </ng-template>
        </app-tab-item>
        <app-tab-item [templateRef]="historyRef" [alwaysRender]="false">
          <span class="tab-item">History</span>
          <ng-template #historyRef>
            <app-consignment-history-details [consignmentProcess]="consignmentProcess"></app-consignment-history-details>
          </ng-template>
        </app-tab-item>
      </ng-container>
    </app-tab-panel>
  </div>

  <div class="modal-footer" *ngIf="!consignmentProcess.cancelled">
    <div class="d-flex flex-grow-1">
      <div class="container">
        <div class="row mx-4">
          <div class="col-auto" *ngIf="!consignmentProcess.fromVisit">
            <button type="button" class="btn btn-outline-danger" (click)="remove()">Remove</button>
          </div>
          <div class="col-auto ms-auto">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" aria-label="Close"
                    *ngIf="!editMode">
              Close
            </button>
            <button type="button" class="btn btn-outline-secondary" *ngIf="editMode" (click)="cancel()">
              Cancel
            </button>
          </div>
          <div class="col-auto">
            <ng-container *ngIf="editMode else readMode">
              <button type="button" class="btn btn-primary" (click)="toggleEdit()"
                      *ngIf="utils.hasBeenDeclared(consignmentProcess)">Ok
              </button>
              <button type="button" class="btn btn-primary" (click)="save()"
                      *ngIf="!utils.hasBeenDeclared(consignmentProcess)">Save
              </button>
              <!--Disable the tooltip when consignment is complete-->
              <app-tooltip *ngIf="!utils.hasBeenDeclared(consignmentProcess) && isAllowedToDeclare" [placement]="'top'"
                           [disabled]="(consignmentProcess.complete || isNewConsignment || consignmentProcess.expectedNumberOfHouseConsignments === 0)">
                <button type="button" class="btn btn-primary mx-4" (click)="declare()"
                        [disabled]="!consignmentProcess.complete && !isNewConsignment && consignmentProcess.expectedNumberOfHouseConsignments > 0">
                  Declare
                </button>
                <div *ngIf="!consignmentProcess.complete && !isNewConsignment && consignmentProcess.expectedNumberOfHouseConsignments > 0" class="tooltipContent">
                  <div class="text-start">
                    Not allowed to declare yet since the expected number of house consignments
                    ({{ consignmentProcess.expectedNumberOfHouseConsignments }}) is not equal to the actual amount of
                    house consignments ({{ masterLevel.consignmentsHouseLevel?.length }})
                  </div>
                </div>
              </app-tooltip>
            </ng-container>
            <ng-template #readMode>
              <button *ngIf="isEditable" type="button" class="btn btn-outline-secondary me-4" (click)="startEditing()">
                Edit
              </button>
              <!--Disable the tooltip when consignment is complete-->
              <app-tooltip *ngIf="isAllowedToDeclare" [placement]="'top'"
                           [disabled]="(consignmentProcess.complete || isNewConsignment || consignmentProcess.expectedNumberOfHouseConsignments === 0)">
                <button type="button" class="btn btn-primary" (click)="declare()"
                        [disabled]="!consignmentProcess.complete && !isNewConsignment && consignmentProcess.expectedNumberOfHouseConsignments > 0">
                  {{ utils.hasBeenDeclared(consignmentProcess) ? 'Update' : 'Declare' }}
                </button>
                <div *ngIf="!consignmentProcess.complete && !isNewConsignment && consignmentProcess.expectedNumberOfHouseConsignments > 0" class="tooltipContent">
                  <div class="text-start">
                    Not allowed to declare yet since the expected number of house consignments
                    ({{ consignmentProcess.expectedNumberOfHouseConsignments }}) is not equal to the actual amount of
                    house consignments ({{ masterLevel.consignmentsHouseLevel?.length }})
                  </div>
                </div>
              </app-tooltip>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #mainFilingTooltip>
  <div class="text-start">
    <h6>Filing choice</h6>
    <p>‘Customs Filing’ means that filing to customs will be sent.</p>
    <p>‘No Customs Filing’ means that no filing to customs will be sent.</p>
  </div>
</ng-template>

<ng-template #filingTypeTooltip>
  <div class="text-start">
    <h6>Filing type</h6>
    <p>‘Single filing’ means you submit all necessary Entry Summary Declaration (ENS) data.</p>
    <p>‘Single filing’ in combination with ‘Master Bill of Lading’ means you also have to provide (multiple) House Bill
      of Lading details.</p>
    <p>‘Multiple filing’ means that parts of the ENS data is provided by the ‘Supplementary declarant’ you specify.</p>
  </div>
</ng-template>

<ng-template #billOfLadingTooltip>
  <div class="text-start">
    <h6>Bill of lading</h6>
    <p>‘Straight’ Bill of Lading (B/L) is non-negotiable and is also known as 'B/L op naam' or 'Recta connossement'.</p>
    <p>To help you choose, you can check the following on your B/L: Is the consignee known by name or unknown (‘To
      order’)? If known, we suggest choosing ‘Straight’. If unknown, we suggest choosing ‘Master’.</p>
    <p>If you are not familiar with using B/L’s but ‘Bookings’, we also suggest using ‘Straight’.</p>
  </div>
</ng-template>
<ng-template #modeOfTransportTooltip>
  <div class="text-start">
    <h6>Mode of transport</h6>
    <p>The mode of transport is either maritime or road.</p>
  </div>
</ng-template>
