import {Component} from '@angular/core';
import {
  DangerInformation,
  Exemption_4_11,
  Exemption_4_11_ReasonType
} from '@portbase/bezoekschip-service-typescriptmodels';
import {ExemptionBaseComponent} from "../exemption_base/exemption_base.component";
import {PortvisitUtils} from "../../../../../refdata/portvisit-utils";

@Component({
  selector: 'app-visit-exemptions-exemption-4-11',
  templateUrl: './exemption_4_11.component.html',
  styleUrls: ['./exemption_4_11.component.css']
})
export class Exemption_4_11Component extends ExemptionBaseComponent {

  exemptionSubType: Exemption_4_11;
  reasonTypes: Exemption_4_11_ReasonType[] = [null, Exemption_4_11_ReasonType.CLEANING_OR_VENTILATING, Exemption_4_11_ReasonType.CLEANING_OF_SUBSTANCES];

  reasonTypesFormatter = (reasonType: Exemption_4_11_ReasonType) => {
    switch (reasonType) {
      case null:
        return 'None';
      case "CLEANING_OR_VENTILATING":
        return 'Cleaning or ventilating outside a location designated by the Harbour Master';
      case "CLEANING_OF_SUBSTANCES":
        return 'Cleaning of substances to be transported enclosed pursuant to the ADN';
    }
  };

  dangerousGoods?: DangerInformation
  portVisitUtils = PortvisitUtils;

  onDangerousGoodsChange(dangerousGoods: DangerInformation) {
    this.exemption['substanceName'] = dangerousGoods.name;
    this.exemption['unVnNumber'] = dangerousGoods.unCode;
  }
}

